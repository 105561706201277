import React, { useRef, useEffect, useState } from "react";
import { useWindowSize } from "@utils";
import Papa from "papaparse";
import { AnimatePresence } from "framer-motion";

import { Text, Icon, Toggle } from "@atoms";
import { geoEqualEarth, geoPath, json, select, interpolateHsl } from "d3";
import Location from "./Location";

const LocationMap = ({ image, data }) => {
  const [locations, setLocations] = useState();
  const [projectedLocations, setProjectedLocations] = useState();
  const [paths, setPaths] = useState([]);
  const [activeFilters, setActiveFilters] = useState([
    "testimonial",
    "partner",
    "candidate",
  ]);
  // const [width, setWidth] = useState();
  const { innerWidth: windowSize } = useWindowSize();
  const map = useRef();

  const updateFilters = event => {
    const filter = event.target.name;

    setActiveFilters(prevActiveFilters => {
      if (prevActiveFilters.includes(filter)) {
        return prevActiveFilters.filter(
          activeFilter => activeFilter !== filter
        );
      }
      return [...prevActiveFilters, filter];
    });
  };

  // download csv from Craft and parse
  useEffect(() => {
    if (data) {
      Papa.parse(data, {
        download: true,
        header: true,
        complete(res) {
          const _locations = res.data
            // .filter(item => item.name?.length)
            .map(item => ({
              ...item,
              ...Object.entries(item).reduce(
                (a, [k, v]) => ({ ...a, [k.replace("original_", "")]: v }),
                {}
              ),
            }))
            .map((item, i) => ({
              ...item,
              id: i,
              type: item?.type?.toLowerCase(),
            }));

          setLocations(_locations);
        },
      });
    }
  }, []);

  // place locations on the map
  useEffect(() => {
    // use d3 to project coordinates
    const svg = select(map.current);
    if (svg.node()) {
      const svgWidth = svg.node().getBoundingClientRect().width;
      const svgHeight = svg.node().getBoundingClientRect().height;
      const projection = geoEqualEarth(); // .scale(200).translate([630, 630]);
      const colorScale = interpolateHsl("#191C31", "#929BC8");
      svg.attr("width", svgWidth).attr("height", svgHeight);

      const path = geoPath().projection(projection);

      // load and display the World
      json("/json/world.geo.json").then(topology => {
        projection.fitExtent(
          [
            [0, 0],
            [svgWidth, svgHeight],
          ],
          topology
        );

        setProjectedLocations(() =>
          locations
            // filter locations based on activeFilters
            ?.filter(loc => activeFilters.includes(loc.type))
            .map(loc => {
              const [x, y] = projection([
                parseFloat(loc.lon),
                parseFloat(loc.lat),
              ]);
              return { ...loc, ...{ x, y } };
            })
        );

        setPaths(
          topology.features.map(f => ({
            d: path(f),
            c: colorScale(((f.properties.mapcolor9 % 7) + 1) / 8),
          }))
        );
      });
    }
  }, [windowSize, locations, activeFilters]);

  return (
    <div>
      <div className="map relative pb-[50%]">
        <svg ref={map}>
          <g>
            {paths.map(p => (
              <path d={p.d} fill={p.c} />
            ))}
          </g>
        </svg>
        <div className="absolute inset-0 z-10 -ml-2 -mt-2">
          <AnimatePresence>
            {projectedLocations?.map(loc => {
              const { id } = loc;
              return (
                <Location
                  key={id}
                  coordinates={{ x: loc.x, y: loc.y }}
                  {...loc}
                />
              );
            })}
          </AnimatePresence>
        </div>
      </div>
      <div className="mx-auto mt-8 flex max-w-4xl flex-wrap items-center justify-center gap-8 md:flex-nowrap">
        <div className="flex items-center gap-2">
          <div className="h-5 w-5 flex-shrink-0">
            <Icon name="speechBubble" className="w-full" />
          </div>
          <Text variant="sm">Testimonials/Case Studies</Text>
        </div>
        <Toggle
          id="partnerToggle"
          name="partner"
          label="Corporate Partners"
          checked={activeFilters.includes("partner")}
          onChange={updateFilters}
          className="after:bg-mint peer-checked:bg-midnight peer-checked:after:bg-white peer-checked:after:bg-white"
        />
        <Toggle
          id="candidateToggle"
          name="candidate"
          label="BoardLeaders"
          checked={activeFilters.includes("candidate")}
          onChange={updateFilters}
          className="after:bg-carnation peer-checked:bg-midnight peer-checked:after:bg-white peer-checked:after:bg-white"
        />
      </div>
    </div>
  );
};

LocationMap.defaultProps = {};

export default LocationMap;
